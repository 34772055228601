$(document).foundation();

// My script
// var $menu = $('[id^=menu-] li');
// var $menu1 = $('#menu-1 li');
// $menu.on('click', function () {
// 	$menu.removeClass('active');
// 	var lindex = $menu.index(this);
// 	var $this = $(this).addClass('active');
// 	if (lindex < 4) {
// 		var n = lindex + 1
// 		var nth = '#menu-1 li:nth-child('+n+')';
// 		var $menu1n = $(nth);
// 		$this;
// 		$menu1n.addClass('active');
// 	} else {
// 		var nth2 = '#menu-2 li:nth-child('+(lindex-3)+')';
// 		var $menu2n = $(nth2);
// 		$this
// 		$menu2n.addClass('active');
// 	}
// });
